/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faTachometerAlt, faLayerGroup, faArrowDown, faArrowUp, faSignOutAlt  } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';


const Sidebar = () => {

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isDashboardSubMenuOpen, setIsDashboardSubMenuOpen] = useState(false);
    const [isPagesSubMenuOpen, setIsPagesSubMenuOpen] = useState(false);
    const navigate = useNavigate(); 

    // Sidebar toggle function
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    // Submenu toggle functions
    const toggleDashboardSubMenu = () => {
        setIsDashboardSubMenuOpen(!isDashboardSubMenuOpen);
    };

    const togglePagesSubMenu = () => {
        setIsPagesSubMenuOpen(!isPagesSubMenuOpen);
    };

    const logout = () => {
        localStorage.removeItem('admin')
        navigate("/login");
    }

    return (

        <>
            <div className="bg-white shadow p-4 flex justify-between items-center fixed w-full z-20">
                <button onClick={toggleSidebar} className="text-2xl text-gray-600">
                    <FontAwesomeIcon icon={faBars} /> {/* Menu Icon */}
                </button>
                
            </div>

            <div
                className={`fixed inset-y-0 left-0 z-30 w-72 lg:w-[16.5%] bg-white shadow-lg p-5 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
                    } lg:translate-x-0 transition-transform duration-300 ease-in-out`}
            >
                {/* Close button for small screens */}
                <button
                    onClick={toggleSidebar}
                    className="lg:hidden absolute top-4 right-4 text-gray-600"
                >
                    <FontAwesomeIcon icon={faTimes} className="text-2xl" />
                </button>

                <div className="text-3xl font-bold mb-10 text-gray-800 ml-0 lg:ml-5">Dashboard</div>

                <ul>
                    {/* Sidebar item with submenu */}
                    <li className="mb-6">
                        <button
                            className="flex items-center justify-between p-3 text-gray-700 hover:bg-gray-300 bg-gray-200 rounded-lg transition duration-200 w-full text-left"
                            onClick={toggleDashboardSubMenu}
                        >
                            <span className="flex items-center">
                                <FontAwesomeIcon icon={faTachometerAlt} className="mr-3" />
                                <span className="font-semibold">Dashboard</span>
                            </span>
                            
                            <FontAwesomeIcon
                                icon={isDashboardSubMenuOpen ? faArrowUp : faArrowDown}
                                className="transition-transform"
                            />
                        </button>
                        <div
                            className={`overflow-hidden transition-max-height duration-300 ease-in-out ${isDashboardSubMenuOpen ? 'max-h-40' : 'max-h-0'
                                }`}
                        >
                            <ul className="ml-4 mt-2">
                                <li className="mt-2">
                                    <Link
                                        to="/"
                                        className="block p-2 text-gray-600 hover:bg-gray-300 rounded-lg transition duration-200"
                                    >
                                        Home
                                    </Link>
                                </li>
                                <li className="mt-2">
                                    <a
                                        href="#"
                                        className="block p-2 text-gray-600 hover:bg-gray-300 rounded-lg transition duration-200"
                                    >
                                        Analytics
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>

                    {/* Sidebar item with submenu */}
                    <li className="mb-6">
                        <button
                            className="flex items-center justify-between p-3 text-gray-700 hover:bg-gray-300 rounded-lg transition duration-200 w-full text-left"
                            onClick={togglePagesSubMenu}
                        >
                            <span className="flex items-center">
                                <FontAwesomeIcon icon={faLayerGroup} className="mr-3" />
                                <span className="font-semibold">Pages</span>
                            </span>
                            <FontAwesomeIcon
                                icon={isPagesSubMenuOpen ? faArrowUp : faArrowDown}
                                className="transition-transform"
                            />
                        </button>
                        <div
                            className={`overflow-hidden transition-max-height duration-300 ease-in-out ${isPagesSubMenuOpen ? 'max-h-40' : 'max-h-0'
                                }`}
                        >
                            <ul className="ml-4 mt-2">
                                <li className="mt-2">
                                    <Link
                                        to="/admin"
                                        className="block p-2 text-gray-600 hover:bg-gray-300 rounded-lg transition duration-200"
                                    >
                                        Manage Tenant
                                    </Link>
                                </li>
                                <li className="mt-2">
                                    <a
                                        href="#"
                                        className="block p-2 text-gray-600 hover:bg-gray-300 rounded-lg transition duration-200"
                                    >
                                        Settings
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>

                    {/* Simple sidebar item */}
                    <li>
                        <a
                            href="#"
                            onClick={logout}
                            className="flex items-center p-3 text-gray-700 hover:bg-gray-200 rounded-lg transition duration-200"
                        >
                            <FontAwesomeIcon icon={faSignOutAlt } className="mr-3" />
                            <span className="font-semibold">Logout</span>
                        </a>
                    </li>
                </ul>
            </div>

            {/* Overlay for small screens */}
            {isSidebarOpen && (
                <div
                    className="fixed inset-0 bg-black opacity-50 z-20 lg:hidden"
                    onClick={toggleSidebar}
                ></div>
            )}
        </>

    );
};

export default Sidebar;