/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import DeleteIcon from '../component/icon/DeleteIcon';
import UpdateIcon from '../component/icon/UpdateIcon';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ManageDomain = () => {
    const [tenants, setTenants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const admin = JSON.parse(localStorage.getItem('admin'));


    const fetchTenants = async () => {
        try {
            const response = await fetch('http://127.0.0.1:8000/api/get-tenant', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${admin.token}`, // Replace with your actual token
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            console.log(data.tenants)
            if (data.success) {
                setTenants(data.tenants);
            }

        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTenants();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }


    const TenantLogin = async (item) => {
        const id = item?.id;
        console.log(id);
    
        try {
            const response = await axios.post("http://127.0.0.1:8000/api/tenant-login", { id }, {
                headers: {
                    'Authorization': `Bearer ${admin.token}`
                }
            });
            
            if (response.data.success) {
                toast.success("Tenant Login Successfully");
    
                // Use environment variable for the base URL
                const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:3000'; // Fallback for local development
    
                // Open the tenant admin page dynamically based on the response
                setTimeout(() => {
                    window.open(`https://${response.data.tenant.domain}/tenant-admin/${response.data.tenant.tenant_id}`, '_blank');
                }, 1000);
            } else {
                toast.error("Tenant Login failed");
            }
        } catch (err) {
            console.error(err);
            toast.error("An error occurred during tenant creation");
        } finally {
            setLoading(false);
        }
    };

    

    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <ToastContainer/>
            <div className="flex items-center justify-between py-10 mx-5 lg:mx-20">
                <h2 className='text-xl'>Manage Domain</h2>
                <a
                    href='/create-tenent'
                    type="button"
                    className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                >
                    Create Tenant
                </a>
            </div>
            <table className="min-w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 text-center">
                    <tr>
                        <th scope="col" className="px-6 py-3">Name</th>
                        <th scope="col" className="px-6 py-3">Email</th>
                        <th scope="col" className="px-6 py-3">Domain</th>
                        <th scope="col" className="px-6 py-3">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {tenants.map((tenant, index) => (
                        <tr key={index} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 text-center">
                            <td className="px-6 py-4 text-center">{tenant.name}</td>
                            <td className="px-6 py-4 text-center">{tenant.email}</td>
                            <td className="px-6 py-4 text-center">{tenant.domain}</td>
                            <td className="px-6 py-4 flex items-center gap-4 justify-center">

                                <button
                                    onClick={()=>TenantLogin(tenant)}
                                    type="button"
                                    className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                                >
                                    Login
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ManageDomain;
