import './App.css';
import Dashboard from './dashboard/Dashboard';
import { Route, Routes } from 'react-router-dom';
import Home from './dashboard/Home';
import ManageDomain from './dashboard/ManageDomain';
import Login from './dashboard/Login';
import AdminMiddleware from './component/AdminMiddleware';
import CreateTenent from './dashboard/CreateTenent';
import UserDashboard from './dashboard/UserDashbaord';


function App() {
  return (
    <>
      <Routes>

        <Route path="/login" element={<Login />} />
        <Route path="/tenant-admin/:id" element={<UserDashboard/>} /> 


        <Route path="/" element={<Dashboard />}>
          <Route index element={<AdminMiddleware><Home /></AdminMiddleware>} />
          <Route path="admin" element={<AdminMiddleware><ManageDomain /></AdminMiddleware>} />
          <Route path="create-tenent" element={<AdminMiddleware><CreateTenent /></AdminMiddleware>} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
