import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const CreateTenent = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [domain, setDomain] = useState("");
    const [password, setPassword] = useState("");
  
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); 
  
    const admin = JSON.parse(localStorage.getItem('admin'));

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        // Replace 'your_token_here' with your actual token

        const token = admin.token;
        
        try {
            const response = await axios.post("http://127.0.0.1:8000/api/create-tenent", {
                name,
                email,
                domain,
                password
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.data.success) {
                toast.success("Tenant created successfully");
            } else {
                toast.error("Tenant creation failed");
            }
        } catch (err) {
            console.error(err);
            toast.error("An error occurred during tenant creation");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center">
        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
          <h2 className="text-2xl font-bold mb-6 text-center text-gray-700">Create Tenent</h2>
          <ToastContainer />
          <form onSubmit={handleSubmit}>
          <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2" htmlFor="email">
                Name
              </label>
              <input
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                type="name"
                id="name"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2" htmlFor="email">
                Email
              </label>
              <input
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                type="email"
                id="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2" htmlFor="email">
                Domain Name
              </label>
              <input
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                type="text"
                id="domain"
                placeholder="Enter Domain Name"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
                required
              />
            </div>
  
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2" htmlFor="password">
                Password
              </label>
              <input
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                type="password"
                id="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

  
            <button
              className="w-full mt-5 bg-blue-500 text-white font-medium py-3 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
              type="submit"
              disabled={loading}
            >
              {loading ? "Creating..." : "Create Tenent"}
            </button>
          </form>
        </div>
      </div>
    );
};

export default CreateTenent;