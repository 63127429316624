import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';

const Home = () => {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-6 w-full">
            {/* Card: Total Visitors */}
            <div className="bg-white shadow rounded-lg p-6">
                <div className="flex items-center justify-between">
                    <div className="text-lg font-bold text-gray-700">Total Visitors</div>
                    <FontAwesomeIcon icon={faChartPie} className="text-gray-500" />
                </div>
                <div className="text-3xl font-bold mt-4 text-gray-800">4564</div>
            </div>

            {/* Card: Revenue */}
            <div className="bg-white shadow rounded-lg p-6">
                <div className="flex items-center justify-between">
                    <div className="text-lg font-bold text-gray-700">Revenue</div>
                    <FontAwesomeIcon icon={faChartPie} className="text-gray-500" />
                </div>
                <div className="text-3xl font-bold mt-4 text-gray-800">$7564</div>
            </div>

            {/* Card: Orders */}
            <div className="bg-white shadow rounded-lg p-6">
                <div className="flex items-center justify-between">
                    <div className="text-lg font-bold text-gray-700">Orders</div>
                    <FontAwesomeIcon icon={faChartPie} className="text-gray-500" />
                </div>
                <div className="text-3xl font-bold mt-4 text-gray-800">7891+</div>
            </div>

            {/* Card: Items */}
            <div className="bg-white shadow rounded-lg p-6">
                <div className="flex items-center justify-between">
                    <div className="text-lg font-bold text-gray-700">Items</div>
                    <FontAwesomeIcon icon={faChartPie} className="text-gray-500" />
                </div>
                <div className="text-3xl font-bold mt-4 text-gray-800">486</div>
            </div>
        </div>
    );
};

export default Home;