/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faTachometerAlt, faLayerGroup, faArrowDown, faArrowUp, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';


const UserDashbaord = () => {


    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isDashboardSubMenuOpen, setIsDashboardSubMenuOpen] = useState(false);
    const [isPagesSubMenuOpen, setIsPagesSubMenuOpen] = useState(false);
    const [user, setUser] = useState({});
    const navigate = useNavigate();

    // Sidebar toggle function
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    // Submenu toggle functions
    const toggleDashboardSubMenu = () => {
        setIsDashboardSubMenuOpen(!isDashboardSubMenuOpen);
    };

    const togglePagesSubMenu = () => {
        setIsPagesSubMenuOpen(!isPagesSubMenuOpen);
    };

    const logout = () => {
        localStorage.removeItem('admin')
        navigate("/login");
    }

    const { id } = useParams();

    useEffect(()=>{
        const userData = JSON.parse(localStorage.getItem('user'));
        if(userData){
            setUser(userData);
        }
        
    },[])




    const fetchTenants = async () => {
        try {
            const response = await fetch(`http://127.0.0.1:8000/api/get-user/${id}`);
            const data = await response.json();
            console.log(data)

            if (data.success) {
                setUser(data.userData);
            }

        } catch (error) {
            console.log(error.message);
        } finally {
            console.log(false);
        }
    };

    useEffect(() => {
        fetchTenants();
    }, []);
 

    return (
        <div className="min-h-screen bg-gray-100 grid grid-cols-12">

            <div className="col-span-12 lg:col-span-2 mx-2">
                <div className="bg-white shadow p-4 flex justify-between items-center fixed w-full z-20">
                    <button onClick={toggleSidebar} className="text-2xl text-gray-600">
                        <FontAwesomeIcon icon={faBars} /> {/* Menu Icon */}
                    </button>

                </div>

                <div
                    className={`fixed inset-y-0 left-0 z-30 w-72 lg:w-[16.5%] bg-white shadow-lg p-5 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
                        } lg:translate-x-0 transition-transform duration-300 ease-in-out`}
                >
                    {/* Close button for small screens */}
                    <button
                        onClick={toggleSidebar}
                        className="lg:hidden absolute top-4 right-4 text-gray-600"
                    >
                        <FontAwesomeIcon icon={faTimes} className="text-2xl" />
                    </button>

                    <div className="text-3xl font-bold mb-10 text-gray-800 ml-0 lg:ml-5">Dashboard</div>

                    <ul>
                        {/* Sidebar item with submenu */}
                        <li className="mb-6">
                            <button
                                className="flex items-center justify-between p-3 text-gray-700 hover:bg-gray-300 bg-gray-200 rounded-lg transition duration-200 w-full text-left"
                                onClick={toggleDashboardSubMenu}
                            >
                                <span className="flex items-center">
                                    <FontAwesomeIcon icon={faTachometerAlt} className="mr-3" />
                                    <span className="font-semibold">Dashboard</span>
                                </span>

                                <FontAwesomeIcon
                                    icon={isDashboardSubMenuOpen ? faArrowUp : faArrowDown}
                                    className="transition-transform"
                                />
                            </button>
                            <div
                                className={`overflow-hidden transition-max-height duration-300 ease-in-out ${isDashboardSubMenuOpen ? 'max-h-40' : 'max-h-0'
                                    }`}
                            >
                                <ul className="ml-4 mt-2">
                                    <li className="mt-2">
                                        <Link
                                            to="/"
                                            className="block p-2 text-gray-600 hover:bg-gray-300 rounded-lg transition duration-200"
                                        >
                                            Home
                                        </Link>
                                    </li>
                                    <li className="mt-2">
                                        <button
                                            onClick={logout}
                                            className="block p-2 text-gray-600 hover:bg-gray-300 rounded-lg transition duration-200"
                                        >
                                            Logout
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </li>



                    </ul>
                </div>

                {/* Overlay for small screens */}
                {isSidebarOpen && (
                    <div
                        className="fixed inset-0 bg-black opacity-50 z-20 lg:hidden"
                        onClick={toggleSidebar}
                    ></div>
                )}
            </div>


            <div className="col-span-12 lg:col-span-10 mx-2 pt-20">
                <h1>This Is User Dashboard Tenant</h1>
                <h1>Name : {user?.name}</h1>
                <h1>Email : {user?.email}</h1>
            </div>



        </div>
    );
};

export default UserDashbaord;
