import React from 'react';
import Sidebar from '../component/Sidebar';
import { Outlet } from "react-router-dom";


const Dashboard = () => {


    return (
        <div className="min-h-screen bg-gray-100 grid grid-cols-12">

            <div className="col-span-12 lg:col-span-2 mx-2">
                <Sidebar />
            </div>


            <div className="col-span-12 lg:col-span-10 mx-2 pt-20">
                <Outlet />
            </div>



        </div>
    );
};

export default Dashboard;
